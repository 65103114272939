import { Component, computed, model } from '@angular/core';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, switchMap } from 'rxjs';
import { CompanyPublicProfileGQL, Item } from '../../../../../../graphql/generated';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CollapsibleHeaderComponent
} from '../../../../core/components/menus/collapsible-header/collapsible-header.component';
import { PublicItemComponent } from './public-item/public-item.component';
import { InventoryCategory, InventoryCategoryBuilder } from '../../../inventory-category.builder';


@Component({
    selector: 'company-public-overview',
    standalone: true,
    imports: [
        AutoAnimateDirective,
        CollapsibleHeaderComponent,
        PublicItemComponent
    ],
    templateUrl: './company-public-overview.component.html'
})
export class CompanyPublicOverviewComponent {

    public searchQuery = model<string>('')

    // TODO: Refactor this to use the apollo state manager
    protected readonly company = toSignal(
        this.activatedRoute.data.pipe(
            switchMap(data => data['company']
                ? this.companyProfileGQL.watch({ slug: data['company'].slug }).valueChanges
                : []
            ),
            map(result => result.data?.company)
        )
    );

    protected readonly items = computed<Item[]>(() => this.company()
        ? this.company()!.items as unknown as Item[]
        : []
    )

    public itemsFiltered = computed<Item[]>(() => {
        return this.items().filter(item => {
            if (!this.searchQuery()) {
                return true
            } else {
                const fullText = [
                    item.equipmentIdentifier,
                    item.category!.name,
                    item.category!.pluralName,
                    item.location.properties.formattedAddress
                ].join(' ').toLowerCase()

                return fullText.includes(this.searchQuery().toLowerCase())
            }
        })
    });

    protected readonly categories = computed<InventoryCategory[]>(() => {
        const categories = InventoryCategoryBuilder.buildInventoryCategories(
            this.itemsFiltered(),
            this.company()?.categories ?? []
        )

        return InventoryCategoryBuilder.orderCategories(categories).filter(category => category.items.length > 0);
    })

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly companyProfileGQL: CompanyPublicProfileGQL
    ) {
    }

    async selectItem(item: any) {
        return this.router.navigate([ '/', 'aanbod', item.encodedId ])
    }

    // TODO: Implement later when the feature is needed
    onSearchQueryChange($event: string) {
        this.searchQuery.set($event)
    }

    public orderItemsByAvailability(items: Item[]) {
        // Based on the item.isListedAndAvailable property, we can sort the items in the category.
        // If the item is listed and available, it should be displayed first.
        return items.sort((a, b) => {
            if (a.status?.isListedAndAvailable && !b.status?.isListedAndAvailable) {
                return -1
            } else if (!a.status?.isListedAndAvailable && b.status?.isListedAndAvailable) {
                return 1
            } else {
                return 0
            }
        })
    }
}
